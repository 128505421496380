import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import { Home } from "../pages/Home";
import ResetPassword from "../pages/ResetPassword";
import Register from "../pages/Register";
import Download from "../pages/Download";

export default function AppRouter() {
    return (<BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/reset-password" element={<ResetPassword />}/>
        <Route path="/register" element={<Register />}/>
        <Route path="/download" element={<Download />}/>
      </Routes>
    </BrowserRouter>)
}