import { Button, Form, Input } from "antd";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import swal from 'sweetalert';
import { HC } from "../../glob/const";
import Http from "../../serv/Http";
import { swalCover } from "../../serv/SwalCover";
import { Header } from "./Home/Header";

export interface IResetPasswordForm {
    newPassword: string
    newPasswordConfirm: string
}

export default function ResetPassword() {
    const [form] = Form.useForm<IResetPasswordForm>()
    const navigate = useNavigate()
    const [qs] = useSearchParams()
    const token = qs.get('token')

    useEffect(() => {
        (async () => {
            if (token) return
            await swal('Lỗi', 'Đường dẫn không hợp lệ, không thể đặt lại mật khẩu', 'error')
            navigate('/download')
        })().catch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    const doResetPassword = swalCover(async (values: IResetPasswordForm) => {
        await Http.req('PUT', `${HC.API_HOST}/auth/reset-password`, {
            token,
            password: values.newPassword
        })
        await swal('Thành công', 'Đã đặt lại mật khẩu thành công', 'success')
        navigate('/download')
    })('Không thể đặt lại mật khẩu', 'Lỗi')

    return (<div>
        <Header />
        <Form style={{ margin: '40px 20px' }} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} form={form} onFinish={doResetPassword} initialValues={{
            newPassword: '',
            newPasswordConfirm: ''
        }}>
            <Form.Item name="newPassword" label="Mật khẩu mới" rules={[
                { required: true, min: 8, message: 'Mật khẩu phải có ít nhất 8 ký tự' }
            ]}>
                <Input.Password />
            </Form.Item>
            <Form.Item name="newPasswordConfirm" label="Nhập lại mật khẩu" rules={[
                ({ getFieldValue }) => ({
                    async validator(_, value) {
                        if (!!value && getFieldValue('newPassword') !== value) throw new Error(`Mật khẩu nhập lại không khớp`)
                    }
                })
            ]}>
                <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 16, xs: { offset: 0 }, md: { offset: 4 } }}>
                <Button type="primary"  htmlType="submit">Đặt lại mật khẩu</Button>
            </Form.Item>
        </Form>
    </div>)
}