import { Button, QRCode, Typography } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { HC } from "../../glob/const";
import { Header } from "./Home/Header";

export default function Download() {
    const [qs] = useSearchParams()

    useEffect(() => {
        if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i))) {
            window.location.href = qs.has('open-app') ? 'ghmobile://' : "https://itunes.apple.com/us/app/gạo-hạt-điểm-giao/id1531046852?ls=1&mt=8"
        }
        else if(navigator.userAgent.match(/android/i)) {
            window.location.href = qs.has('open-app') ? 'ghmobile://' : "https://play.google.com/store/apps/details?id=concon.vnr_android"
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<div>
        <Header />
        <div style={{ marginTop: '40px', height: '75vh', display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
            <Typography.Title level={2}>Tải App Gạo Hạt ngay</Typography.Title>
            <QRCode value={HC.DOWNLOAD_URL} icon="logo192.png" size={256} />
            <Button style={{ marginTop: '1rem' }} type="primary" onClick={() => window.location.href = HC.DOWNLOAD_URL}>Tải app</Button>
        </div>
    </div>)
}