import { Button, Form, Input } from "antd";
import { useNavigate, useNavigation, useSearchParams } from "react-router-dom";
import swal from 'sweetalert';
import { HC } from "../../glob/const";
import Http from "../../serv/Http";
import { swalCover } from "../../serv/SwalCover";
import { Header } from "./Home/Header";

export interface IRegisterForm {
    name: string
    phone: string
    password: string
    passwordConfirm: string
    email: string
    refCode: string
}

export default function Register() {
    const [form] = Form.useForm<IRegisterForm>()
    const navigate = useNavigate()
    const [qs] = useSearchParams()
    const refCode = qs.get('ref_code')

    const doRegister = swalCover(async (values: IRegisterForm) => {
        await Http.req('POST', `${HC.API_HOST}/auth/reg`, {
            name: values.name,
            phone: values.phone,
            email: values.email,
            password: values.password,
            ref_code: values.refCode
        })
        await swal('Thành công', 'Đã đăng ký thành công', 'success')
        navigate('/download')
    })('Đã có lỗi khi đăng ký, xin vui lòng thử lại', 'Lỗi')

    return (<div>
        <Header />
        <Form style={{ margin: '40px 20px' }} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} form={form} onFinish={doRegister} initialValues={{
            name: '',
            phone: '',
            email: '',
            password: '',
            passwordConfirm: '',
            refCode: refCode ?? ''
        }}>
            <Form.Item name="name" label="Họ và tên" rules={[
                { required: true, min: 2, message: 'Bạn phải nhập họ và tên' }
            ]}>
                <Input />
            </Form.Item>
            <Form.Item name="phone" label="Số điện thoại" rules={[
                { required: true, pattern: /^(84|0[3|5|7|8|9])([0-9]{8})$/g, message: 'Bạn phải nhập số điện thoại đúng định dạng' }
            ]}>
                <Input type="number" />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[
                { required: true, type: 'email', message: 'Bạn phải nhập email đúng định dạng' }
            ]}>
                <Input />
            </Form.Item>
            <Form.Item name="password" label="Mật khẩu" rules={[
                { required: true, min: 8, message: 'Mật khẩu phải có ít nhất 8 ký tự' }
            ]}>
                <Input.Password />
            </Form.Item>
            <Form.Item name="passwordConfirm" label="Nhập lại mật khẩu" rules={[
                { required: true },
                ({ getFieldValue }) => ({
                    async validator(_, value) {
                        if (!!value && getFieldValue('password') !== value) throw new Error(`Mật khẩu nhập lại không khớp`)
                    }
                })
            ]}>
                <Input.Password />
            </Form.Item>
            {refCode && <Form.Item name="refCode" label="Mã giới thiệu">
                <Input disabled />
            </Form.Item>}
            <Form.Item wrapperCol={{ offset: 4, span: 16, xs: { offset: 0 }, md: { offset: 4 } }}>
                <Button type="primary"  htmlType="submit">Đăng ký</Button>
            </Form.Item>
        </Form>
    </div>)
}