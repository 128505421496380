import swal from 'sweetalert';
import { ErrorMessageInterpolationService } from './ErrorMessageInterpolation';

export const SWAL_COVER_DEFAULT_TITLE = 'Lỗi'
export const SWAL_COVER_DEFAULT_MSG = 'Vui lòng thử lại'

export const swalCover = (method: Function) => (defaultMsg?: string, title?: string) => {
    defaultMsg = defaultMsg || SWAL_COVER_DEFAULT_MSG
    return async function (...args: any[]) {
        try {
            //@ts-ignore
            return await Promise.resolve(method.apply(this, args))
        }
        catch (err) {
            console.error(err)
            const msg = ErrorMessageInterpolationService.interpolate(err, defaultMsg);
            swal(title || SWAL_COVER_DEFAULT_TITLE, msg, 'error');
        }
    }
}

export function SwalCover(defaultMsg?: string, title?: string) {
    defaultMsg = defaultMsg || SWAL_COVER_DEFAULT_MSG
    return (target: any, key: string, desc: any) => {
        const method = desc.value;
        desc.value = swalCover(method)(defaultMsg, title)
        return desc;
    }
}

export function SwalLoading(title: string = "Loading...") {
    return (target: any, key: any, desc: any) => {
        const method = desc.value;

        desc.value = async function (...args: any[]) {
            try {
                swal({
                    title: title,
                    closeOnEsc: false,
                    closeOnClickOutside: false,
                    buttons: {
                        closeModal: false
                    }
                })
                return await Promise.resolve(method.apply(this, args))
            }
            catch (err) {
                throw err
            }
            finally {
                swal?.stopLoading?.()
            }
        }

        return desc;
    }
}

export default SwalCover;