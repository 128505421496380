import React from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  NavLink,
  NavbarBrand,
  NavItem,
} from "react-bootstrap";

export function Header() {
  return (
    <>
      <header className="site-header">
        <Container>
          <Row>
            <Col lg={8} xs={12} className="d-flex flex-wrap">
              <p className="d-flex me-4 mb-0">
                <i className="bi-geo-alt me-2"></i>
                39/62 Đặng Thuỳ Trâm, P13, Bình Thạnh, TP.HCM
              </p>
              <p className="d-flex mb-0">
                <i className="bi-envelope me-2"></i>
                <a href="mailto:cskh.gaohat@gmail.com">cskh.gaohat@gmail.com</a>
              </p>
            </Col>
          </Row>
        </Container>
      </header>

      <Navbar expand="lg" bg="light" variant="light" className="shadow-lg">
        <Container>
          <NavbarBrand href="index.html">
            <img src="logo192.png" className="logo img-fluid" alt="Gạo Hạt" />
            <span>
              Con Con<small>Cơm ngon từ Gạo</small>
            </span>
          </NavbarBrand>
          <Navbar.Toggle aria-controls="navbarNav" />
          <Navbar.Collapse id="navbarNav">
            <Nav className="ms-auto">
              <NavItem>
                <NavLink href="#top">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#section_2">Thông tin</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#section_6">Liên hệ</NavLink>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
