import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
const { version } = require('../../../../package.json')

export function Content() {
  return (
    <main>
      <section className="hero-section hero-section-full-height">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Carousel id="hero-slide" fade>
                <Carousel.Item>
                  <img
                    className="carousel-image img-fluid"
                    src="images/slide/volunteer-helping-with-donation-box.jpg"
                    alt="..."
                  />
                  <Carousel.Caption className="d-flex flex-column justify-content-end">
                    <h1>Tiện Dụng</h1>
                    <p>Giao hàng tận nơi, không phí ship, giá cạnh tranh</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="carousel-image img-fluid"
                    src="images/slide/volunteer-selecting-organizing-clothes-donations-charity.jpg"
                    alt="..."
                  />
                  <Carousel.Caption className="d-flex flex-column justify-content-end">
                    <h1>Nhanh Chóng</h1>
                    <p>Không giới hạn phạm vi hoạt động</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="carousel-image img-fluid"
                    src="images/slide/medium-shot-people-collecting-donations.jpg"
                    alt="..."
                  />
                  <Carousel.Caption className="d-flex flex-column justify-content-end">
                    <h1>Tận Tâm</h1>
                    <p>Đáp ứng nhu cầu của mỗi Khách Hàng</p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section-padding">
        <Container>
          <Row>
            <Col lg={10} className="text-center mx-auto">
              <h2 className="mb-5">Chào mừng đến với Gạo Hạt</h2>
            </Col>
          </Row>
          <Row>
            {[
              { img: "hands.png", text: "Đội ngũ <strong>rộng khắp</strong>" },
              {
                img: "heart.png",
                text: "<strong>Giao hàng</strong> nhanh chóng",
              },
              { img: "receive.png", text: "Phục vụ <strong>tận tâm</strong>" },
              {
                img: "scholarship.png",
                text: "<strong>Dịch vụ</strong> thuận tiện",
              },
            ].map((item, index) => (
              <Col lg={3} md={6} className="mb-4 mb-lg-0 mb-md-4" key={index}>
                <div className="featured-block d-flex justify-content-center align-items-center">
                  <a href="donate.html" className="d-block">
                    <img
                      src={`images/icons/${item.img}`}
                      className="featured-block-image img-fluid"
                      alt=""
                    />
                    <p
                      className="featured-block-text"
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    ></p>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="section-padding section-bg" id="section_2">
        <Container>
          <Row>
            <Col lg={6} xs={12} className="mb-5 mb-lg-0">
              <img
                src="images/group-people-volunteering-foodbank-poor-people.jpg"
                className="custom-text-box-image img-fluid"
                alt=""
              />
            </Col>

            <Col lg={6} xs={12}>
              <div className="custom-text-box">
                <h2 className="mb-2">Gạo Hạt</h2>
                <h5 className="mb-3">Cơm ngon đến từ hạt gạo</h5>
                <p className="mb-0">
                  Hệ thống phân phối Gạo bằng ứng dụng công nghệ, mang đến cho
                  khách hàng sản phẩm an toàn, chất lượng cao, với dịch vụ nhanh
                  chóng và giá cả cạnh tranh
                </p>
              </div>

              <Row>
                <Col lg={6} md={6} xs={12}>
                  <div className="custom-text-box mb-lg-0">
                    <h5 className="mb-3">Mục tiêu</h5>
                    <p>
                      Phát triển mạng lưới giao hàng và chiếm lĩnh thị trường
                    </p>
                    <ul className="custom-list mt-2">
                      <li className="custom-list-item d-flex">
                        <i className="bi-check custom-text-box-icon me-2"></i>
                        Điểm giao hàng
                      </li>
                      <li className="custom-list-item d-flex">
                        <i className="bi-check custom-text-box-icon me-2"></i>
                        Đội ngũ bán hàng
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col lg={6} md={6} xs={12}>
                  <div className="custom-text-box d-flex flex-wrap d-lg-block mb-lg-0">
                    <div className="counter-thumb">
                      <div className="d-flex">
                        <span
                          className="counter-number"
                          data-from="1"
                          data-to="100"
                          data-speed="1000"
                        ></span>
                        <span className="counter-number-text"></span>
                      </div>
                      <span className="counter-text">
                        Điểm giao trên khắp TP HCM
                      </span>
                    </div>

                    <div className="counter-thumb mt-4">
                      <div className="d-flex">
                        <span
                          className="counter-number"
                          data-from="0"
                          data-to="3"
                          data-speed="1000"
                        ></span>
                        <span className="counter-number-text">3%</span>
                      </div>
                      <span className="counter-text">Market size</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="contact-section section-padding" id="section_6">
        <Container>
          <Row>
            <Col lg={4} xs={12} className="ms-auto mb-5 mb-lg-0">
              <div className="contact-info-wrap">
                <h2>Liên hệ với chúng tôi ngay</h2>
                <div className="contact-info">
                  <h5 className="mb-3">Thông tin liên hệ</h5>
                  <p className="d-flex mb-2">
                    <i className="bi-geo-alt me-2"></i>
                    39/62 Đặng Thuỳ Trâm, P13, Bình Thạnh, TP.HCM
                  </p>
                  <p className="d-flex mb-2">
                    <i className="bi-telephone me-2"></i>
                    <a href="tel: 028-666-6666">028-666-6666</a>
                  </p>
                  <p className="d-flex">
                    <i className="bi-envelope me-2"></i>
                    <a href="mailto:skh.gaohat@gmail.com">
                      cskh.gaohat@gmail.com
                    </a>
                  </p>
                  <Button href="#" className="custom-btn btn mt-3">
                    Tìm đường
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={5} xs={12} className="mx-auto">
              <form
                className="custom-form contact-form"
                action="#"
                method="post"
                role="form"
              >
                <h2>Gửi thắc mắc của bạn:</h2>
                <p className="mb-4">
                  Bạn cũng có thể gửi email đến:{" "}
                  <a href="#">cskh.gaohat@gmail.com</a>
                </p>
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      className="form-control"
                      placeholder="Họ & tên đệm"
                      required
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      className="form-control"
                      placeholder="Tên"
                      required
                    />
                  </Col>
                </Row>
                <input
                  type="email"
                  name="email"
                  id="email"
                  pattern="[^ @]*@[^ @]*"
                  className="form-control"
                  placeholder="nguyenvana@gmail.com"
                  required
                />
                <textarea
                  name="message"
                  rows={5}
                  className="form-control"
                  id="message"
                  placeholder="Chúng tôi giúp gì được cho bạn?"
                ></textarea>
                <Button type="submit" className="form-control">
                  Gửi
                </Button>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="site-footer">
        <Container>
          <Row>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <h5 className="site-footer-title mb-3">Đường dẫn</h5>
              <ul className="footer-menu">
                <li className="footer-menu-item">
                  <a href="policy.html" className="footer-menu-link">
                    Chính sách & điều khoản
                  </a>
                </li>
                <li className="footer-menu-item">
                  <a href="download" className="footer-menu-link">
                    Tải ứng dụng
                  </a>
                </li>
              </ul>
            </div>

            <Col lg={4} md={6} xs={12} className="mx-auto">
              <h5 className="site-footer-title mb-3">Thông tin liên hệ</h5>
              <p className="text-white d-flex mb-2">
                <i className="bi-telephone me-2"></i>
                <a href="tel: 028-666-6666" className="site-footer-link">
                  028-666-6666
                </a>
              </p>
              <p className="text-white d-flex">
                <i className="bi-envelope me-2"></i>
                <a
                  href="mailto:info@yourgmail.com"
                  className="site-footer-link"
                >
                  cskh.gaohat@gmail.com
                </a>
              </p>
              <p className="text-white d-flex mt-3">
                <i className="bi-geo-alt me-2"></i>
                39/62 Đặng Thuỳ Trâm, P13, Bình Thạnh, TP.HCM
              </p>
              <Button href="#" className="custom-btn btn mt-3">
                Đường Đi
              </Button>
            </Col>
          </Row>
        </Container>

        <div className="site-footer-bottom">
          <Container>
            <Row>
              <Col lg={6} md={7} xs={12}>
                <p className="copyright-text mb-0">
                  Copyright © 2024 <a href="#">Gạo Hạt</a>. - ({version})
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </main>
  );
}
