import React from "react";
import { Content } from "./Content";
import { Header } from "./Header";
import "./index.css";

export function Home() {
  return (
    <div>
      <Header />
      <Content />
    </div>
  );
}
