import _ from 'lodash'
import swal from 'sweetalert'

class HttpRequestError extends Error {
    body: any;

    constructor(msg: string, body: any) {
        super(msg)

        this.body = body;
    }
}

class HttpService {
    async req(method: string, url: string, body?: any, headers?: any, hook?: any) {
        let opts: any = {
            method
        };
        opts.headers = new Headers();
        opts.headers.append('Accept', 'application/json')
        opts.headers.append('Content-Type', 'application/json')

        if (body) {
            opts.body = JSON.stringify(body)
        }

        if (!!headers) {
            for (const h in headers) {
                opts.headers.append(h, headers[h])
            }
        }

        if (hook) {
            [url, opts] = hook(url, opts)
        }

        const resp = await fetch(`${url}`, opts);
        const json = await resp.json();
        
        if (resp.status < 200 || resp.status > 299) {
            throw new HttpRequestError(`HTTP Request error ${resp.status}!`, json)
        }
        
        return json;
    }
}

const Http = new HttpService();
export const useHttp = () => Http

export default Http;